import React, { } from 'react';
import Button from 'react-bootstrap/Button';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import DustTracker from "../Pages/Dusttracker.js"
import magicDusty from "../assets/images/magicDust.png";
import seedChannel from "../assets/images/seedChannel.png";
import wizardLobby from "../assets/images/wizardLobby.png";
import backgroundImage from "../assets/images/8BitPortrait.jpg";

import WizardShop from "../assets/images/wizardShop.jpg"
import ArmsShop from "../assets/images/armsShop.jpg"
import PetShop from "../assets/images/petShop.jpg"




import { Layout } from 'antd';
import { Container } from 'react-bootstrap';
const { Content } = Layout;




export default function Hub() {

    return (
        <Content style={{ padding: '10px', textAlign: 'center', fontSize: 18, backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' + backgroundImage + ')',
        backgroundSize: "cover" }}>
            <div className="mainTitle" style={{fontSize: "10em"}}>
                Wizard Hub
            </div>

            <Container fluid="md">
                <Row>
                    <Col>
                        <Card >

                            <Card >
                                <Card.Body>
                                    <div className="modComps" style={{ color: "black", padding: "0% 15%" }}>
                                        <CardGroup >
                                            <Card >
                                                <Card.Img variant="top" src={magicDusty} />
                                                <Card.Body>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Button variant="primary" rel="noopener noreferrer" href="https://t.me/magicdustcrypto" target="_blank">Dust TG Channel</Button>
                                                </Card.Footer>
                                            </Card>
                                            <Card>
                                                <Card.Img variant="top" src={seedChannel} />
                                                <Card.Body>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Button variant="primary" rel="noopener noreferrer" href="https://t.me/weedminers" target="_blank">Seed TG Channel</Button>
                                                </Card.Footer>
                                            </Card>
                                            <Card>
                                                <Card.Img variant="top" src={wizardLobby} />
                                                <Card.Body>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Button variant="primary" rel="noopener noreferrer" href="https://t.me/niftywizardslobby" target="_blank">NW TG Lobby</Button>
                                                    <DustTracker />
                                                </Card.Footer>
                                            </Card>
                                            
                                            
                                
                            
                                        </CardGroup>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Card>
                    </Col>
                    <Col>
                        <Card >
                            
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br />


            <Container fluid="md">
                <Row>
                    <Col>
                        <Card >
                            <Carousel fade >
                                <Carousel.Item interval={10000000}>
                                    <img
                                        className="d-block w-100"
                                        src={(WizardShop)}
                                        alt="First slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={10000000}>
                                    <img
                                        className="d-block w-100"
                                        src={(ArmsShop)}
                                        alt="Second slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={10000000}>
                                    <img
                                        className="d-block w-100"
                                        src={(PetShop)}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </Card>
                    </Col>
                    <Col>
                        <Card >
                            <Card.Header>
                                <Nav variant="tabs" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="#first">Active</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="#link">Link</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="#disabled" disabled>
                                            Disabled
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>





        </Content>


    );

}

