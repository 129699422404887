import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import React, { } from 'react';
import './index.css';
import { Link } from "react-router-dom"

import * as waxjs from "@waxio/waxjs/dist";


export default function MainNavbar() {

  
  const wax = new waxjs.WaxJS({
    rpcEndpoint: 'https://wax.greymass.com'
    
  });

  //normal login. Triggers a popup for non-whitelisted dapps
  async function login() {
    try {
        //if autologged in, this simply returns the userAccount w/no popup
        const userAccount = await wax.login();
        const pubKeys = wax.pubKeys;
        let str = 'Account: ' + userAccount
        document.getElementById('loginresponse').insertAdjacentHTML('beforeend', str);
    } catch (e) {
        document.getElementById('loginresponse').append(e.message);
    }
} 

  return (
    <Navbar bg="dark" expand="lg" variant="dark" sticky="top" className="mainNav">

      <Navbar.Brand style={{ fontSize: 32 }} as={Link} to={"/"} className="mainNavBrand">

        Nifty Wizards</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          
          <Nav.Link as={Link} to={"/tracker"}>Leaderboards</Nav.Link>
          <Nav.Link as={Link} to={"/storefront"}>Wizard Shop</Nav.Link>
          <Nav.Link rel="noopener noreferrer" href="https://neftyblocks.com/c/niftywizards/blends" target="_blank">Magic Blends</Nav.Link>
          <NavDropdown title="Dust Links" id="basic-nav-dropdown">
            <NavDropdown.Item href="https://neftyblocks.com/c/dust/marketplace?sort=created&order=desc&collection_name=dust" target="_blank">Dust Market</NavDropdown.Item>
            <NavDropdown.Item href="https://neftyblocks.com/c/dust" target="_blank">Dust Drops</NavDropdown.Item>
            <NavDropdown.Item href="https://wizards-guild.gitbook.io/dust-documentation/" target="_blank">Dust Medium</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav style={{ paddingRight: "3%" }}>
          <Nav.Link>
            <h2 id="loginresponse"> </h2>
          </Nav.Link>
          <Nav.Link >
         
          
           

            
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

