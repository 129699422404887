import React, {} from 'react';
import '@neftyblocks/market';
import '../index.css';
import '../App.css';
import backgroundImage from "../assets/images/8BitLandscape.jpg";

import { Layout} from 'antd';
const { Content } = Layout;



export default function Storefront() {
    return (
        <div className="BgCover" style={{ backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('+backgroundImage+')'}} >
          <Content style={{ padding: '120px', paddingTop:"30px", paddingBottom: "30px" , textAlign: 'center', fontSize: 12}}>
          <div className = "mainTitle" style={{fontSize: "12em"}}>
                Wizard Shop
                </div>
                <neftyblocks-market collection="niftywizards" limit="16"></neftyblocks-market> 
          </Content>
           
        
        </div> );
    
}


