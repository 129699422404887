import firebase from 'firebase'
// var config = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
//   apiKey: "AIzaSyDfKf8u9chmiT9SQIhlVxfNHX7ePeKt2zQ",
//   authDomain: "nfts-741c7.firebaseapp.com",
//   databaseURL: "https://nfts-741c7.firebaseio.com",
//   storageBucket: "nfts-741c7.appspot.com",
//   messagingSenderId: "689184473259",
//   projectId: "nfts-741c7",
// };

var config = {
    apiKey: "AIzaSyDqONWIhfxMY0_oPbPzODQ_EGxEJIFpuAo",
    authDomain: "niftywizards-39880.firebaseapp.com",
    databaseURL: "https://niftywizards-39880.firebaseio.com",
    projectId: "niftywizards-39880",
    storageBucket: "niftywizards-39880.appspot.com",
    messagingSenderId: "736148744749",
    appId: "1:736148744749:web:bc9d2ad7575065f173e6a4",
    measurementId: "G-27SVM4W6JZ"
  };

firebase.initializeApp(config);
var db = firebase.firestore();
export default db;


// <!-- The core Firebase JS SDK is always required and must be listed first -->
// <script src="https://www.gstatic.com/firebasejs/7.14.4/firebase-app.js"></script>
//
// <!-- TODO: Add SDKs for Firebase products that you want to use
//      https://firebase.google.com/docs/web/setup#available-libraries -->
// <script src="https://www.gstatic.com/firebasejs/7.14.4/firebase-analytics.js"></script>
//
// <script>
//   // Your web app's Firebase configuration
//   var firebaseConfig = {
//     apiKey: "AIzaSyDfKf8u9chmiT9SQIhlVxfNHX7ePeKt2zQ",
//     authDomain: "nfts-741c7.firebaseapp.com",
//     databaseURL: "https://nfts-741c7.firebaseio.com",
//     projectId: "nfts-741c7",
//     storageBucket: "nfts-741c7.appspot.com",
//     messagingSenderId: "689184473259",
//     appId: "1:689184473259:web:1d6e9145189e75399c77ee",
//     measurementId: "G-N0MSPHPFG3"
//   };
//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);
//   firebase.analytics();
// </script>
