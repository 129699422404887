import React, { } from 'react';
import { Route, Routes } from "react-router-dom"

import MainNavbar from "./Navbar"
import Hub from "./Pages/Hub"
import Tracker from "./Pages/Tracker"
import Storefront from "./Pages/Storefront"
import Homepage from './Pages/Homepage';


import './index.css';
import './App.css';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css'

import { Layout } from 'antd';

import backgroundImage from "./assets/images/BackgroundPauloV1.jpg";
import Neftyblends from './Pages/Neftyblends';




export default function App() {
  const { Footer } = Layout;



  return (
    <div className="BgCover"
      style={{
        backgroundImage: 'url(' + backgroundImage + ')',
        backgroundSize: "cover",
        color: "#f5f5f5"
      }}
    >
      <div className="topNav">

        <MainNavbar
         />
        
        
      </div>


      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/hub" element={<Hub
          />} />
        <Route path="/tracker" element={<Tracker />} />
        <Route path="/storefront" element={<Storefront />} />
        <Route path="/Neftyblends" element={<Neftyblends />} />
      </Routes>

      <Footer style={{ textAlign: 'center' }}>
        <div className="container-fluid">
          <div className="footer">
            <ul className="socials">
              <li><a href="https://t.me/niftywizardslobby"><i className="fab fa-telegram-plane"></i></a></li>
              <li><a href="https://twitter.com/NiftyWizards"><i className="fab fa-twitter"></i></a></li>
              <li><a href="https://medium.com/nifty-wizards"><i className="fab fa-medium-m"></i></a></li>
              <li><a href="https://www.discord.com"><i className="fab fa-discord"></i></a></li>
            </ul>
            <div className="pixelText">
              nifty wizards ©2021
            </div>
          </div>
        </div>
      </Footer>

    </div>
  );
}



// <div className="App">
//   <header className="App-header">
    // <Row>
    //   <Col flex={1}></Col>
    //   <Col flex={2}>
    //     <Table columns={columns} dataSource={data} onChange={onChange} pagination={false}/>
    //   </Col>
    //   <Col flex={1}></Col>
    // </Row>
//
//
//   </header>
// </div>
