import React, { } from 'react';
import '@neftyblocks/market';
import '../index.css';
import '../App.css';

import backgroundImage from "../assets/images/8BitPortrait.jpg";

export default function Neftyblendss() {
    return (
        <div className="BgCover"
        style={{
        backgroundImage: 'url('+backgroundImage+')',
        backgroundSize: "cover",
        color: "#f5f5f5"
      }}
        >
            <h1>Wizard Shop</h1>
        <neftyblocks-market collection="niftywizards" limit="25"></neftyblocks-market> 
        </div> );
    
}


