import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Layout } from 'antd';
import backgroundImage from "../assets/images/BackgroundPauloV1.jpg";
import { Table } from 'antd';
import db from '../fire';

const { Content } = Layout;

let wizards = db.collection('wizards')

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '34%',
  },
  {
    title: 'XP',
    dataIndex: 'xp',
    sorter: {
      compare: (a, b) => a.xp - b.xp,
      multiple: 3,
    },
    width: '33%',
  },
  {
    title: 'Pyro Score',
    dataIndex: 'pyro',
    sorter: {
      compare: (a, b) => a.pyro - b.pyro,
      multiple: 2,
    },
    width: '33%',
  }
];

//const history = useHistory();

// fires when the page loads
function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

export default function Tracker() {

  const [data, setData] = useState([]);
  //let d = []

  // called when the page loads
  useEffect(() => {
    console.log('useEffect')

    let k = 1;
    let d = []
    // load the firebase data
    wizards.orderBy('xp', 'desc').where('xp', '>', 0).limit(50).get().then(snapshot => {
      snapshot.forEach(snap => {

        //console.log(snap.data().telegramUsername)
        let _sd = snap.data();

        let obj = {
          key: String(k),
          name: _sd.telegramUsername,
          pyro: _sd.pyromancerScore || 0,
          xp: _sd.xp,
        }
        d.push(obj)
        k = k + 1;
      })
      console.log("data", d)
      setData(d)
    })
    // get the wizard data sorted on xp
    // create the 'data' json object exactly as it is below
    // feed that data object into the Table
    //console.log("setData")
    //setData(data)
  }, [])
  return (
    <div className="BgCover"
      style={{
        backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(' + backgroundImage + ')',
        backgroundSize: "cover",
        color: "#f5f5f5"
      }}
    >

      <Content style={{ padding: '30px', textAlign: 'center', fontSize: 12 }}>

        <div className = "mainTitle" style={{fontSize: "10em"}}>
          Nifty Wizards Rankings
        </div>

        <div className="site-layout-content">
          <Row>
            <Col flex={1}></Col>
            <Col flex={2}>
              <div className="pixelText">
                <Table columns={columns} dataSource={data} onChange={onChange} pagination={false} />
              </div>
            </Col>
            <Col flex={1}></Col>
          </Row>
        </div>
      </Content>
    </div>
  );
}


// <div className="App">
//   <header className="App-header">
    // <Row>
    //   <Col flex={1}></Col>
    //   <Col flex={2}>
    //     <Table columns={columns} dataSource={data} onChange={onChange} pagination={false}/>
    //   </Col>
    //   <Col flex={1}></Col>
    // </Row>
//
//
//   </header>
// </div>