import React, { } from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ChamberList from "../assets/images/chamberList.gif"

import backgroundImage from "../assets/images/8BitPortrait.jpg";

import { Layout } from 'antd';
import { Container } from 'react-bootstrap';
const { Content } = Layout;


export default function Homepage() {

    return (
        <Content style={{
            backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' + backgroundImage + ')',
            backgroundSize: "cover", padding: '10px',
            textAlign: 'center',
            
        }}>

            <Container  className='cardSpacer'  style={{ color: 'black', paddingTop: "5%" }}>
                <Card >
                    <Card.Body className='customCard'>
                        <h1 className="px-4 , pb-4 , mainTitle">Welcome to Nifty Wizards!</h1>
                        <h1 className="px-4 , pb-4">A Role Playing Game that uses Telegram, Twitter and NFTs.</h1>
                        <Card.Text className="px-4">
                            To start the game, you must join the Nifty Wizards lobby here: https://t.me/niftywizardslobby
                        </Card.Text>
                        <Card.Text className="px-4">
                            You need a WAX Cloud Wallet to play the game: https://all-access.wax.io/.
                        </Card.Text>
                    </Card.Body>
                </Card>



                <Container>
                    <Row className='cardSpacer'>
                        <Col >
                            <Card >
                                <Card.Body className='customCard'>
                                <h1 className="px-4 , pb-4 , subTitle">NFTs — Gaming Items</h1>
                                    <Card.Text className="px-4">
                                        “NFT” is just a fancy way to say an in-game asset.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        For example, key, scroll, staff, knife, coin…
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        The NFTs in Nifty Wizard can be bought, sold, traded, and they give you powers and special perks in the game and the real world.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        build alliances, undermine other Wizards <span role="img" aria-label="wizard">🧙🏼‍♂️</span>
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        Here are some items the game has already.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                    <span role="img" aria-label="wizard">📜</span> Scroll (Base) — Scrolls allow you to participate in dope Quests
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                    <span role="img" aria-label="wizard">💰</span> Gold (Base) — In game currency for trading for items
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                    <span role="img" aria-label="wizard">🌳</span> Weed (Base) — Gives Wizards bonus powers for Quests
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                    <span role="img" aria-label="wizard">🔮</span> Crystal Ball (Base) — Give you access to all master Chambers
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                    <span role="img" aria-label="wizard">🍺</span> Beer (Base) — Beers get wizards drunk. Wizards need to be drunk to do several things.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                    <span role="img" aria-label="wizard">🔑</span> Key (Base) — Keys allow you access to different Chambers
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                    <span role="img" aria-label="wizard">🔪</span> Knife (Base) — Knives are used on missions and future battles
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>

                            <Card>
                                <Card.Body className='customCard'>
                                <h1 className="px-4 , pb-4 , subTitle">Nifty Wizards Objectives</h1>
                                    <Card.Text>
                                        earn, buy and sell your own NFTs (you currently make 95% of your sales)
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        complete quests, level up your wizard with items
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        meet and network with other Nifty Wizards
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        build alliances, undermine other Wizards <span role="img" aria-label="wizard">🧙🏼‍♂️</span>
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        discover chambers
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        become a master of a chamber (to make nfts)
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        help build the game, lore… use your imagination
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <br/>
                            <Card>
                                <Card.Body className='customCard'>
                                <h1 className="px-4 , pb-4 , subTitle">Quests</h1>
                                    <Card.Text className="px-4">
                                        You will need a Scroll <span role="img" aria-label="wizard">📜</span> in order to participate in Quests.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        Once you have Scroll you gain access to the Base Quests Chamber and you can do any of the Base Quests.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        There will be *many* Base Quests.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        Each Quest completed will gain more items, and make you a more powerful wizard.
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Container>


                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body className='customCard'>
                                <h1 className="px-4 , pb-4 , subTitle">Chambers</h1>
                                    <Card.Text>
                                        The Lobby is the first chamber.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        Your purpose in the Lobby is to ask questions and obtain a Key <span role="img" aria-label="wizard">🔑</span>.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        Once you obtain a key you officially start the game and gain access to the “Key Holders” room.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        Here are some of the Chambers already built in the game (in Telegram).
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        <Image src={(ChamberList)} rounded />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body className='customCard'>
                                <h1 className="px-4 , pb-4 , subTitle">Slash Commands</h1>
                                    <Card.Text>
                                        Type the forward slash ‘/’ (in telegram text input in the Lobby) to reveal commands, these will help you on your journey.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        Example of some Slash Commands
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        Once you obtain a key you officially start the game and gain access to the “Key Holders” room.
                                    </Card.Text>
                                    <Card.Text className="px-4">
                                        <Image src={(ChamberList)} rounded />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>






            </Container>







        </Content>


    );

}

